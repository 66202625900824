
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, Await } from 'react-router-dom';
import Loading from './components/Loading';
import Auth from './auth';
import React, { useState, useEffect, useRef } from "react";


function App() {
  
  const Home = lazy(() => import('./pages/HomePage'));
  const NaoMarcamPage = lazy(() => import('./pages/NaoMarcamPage'));
  const Over25Page = lazy(() => import('./pages/Over25Page'));
  const Under15Page = lazy(() => import('./pages/Under15Page'));
  const Over15Page = lazy(() => import('./pages/Over15Page'));
  const Login = lazy(() => import('./pages/Login'));
  const Cadastro = lazy(() => import('./pages/Cadastro'));
  const Classificacao = lazy(() => import('./pages/ClassificacaoPage'));
  const PalpitesJogo = lazy(() => import('./pages/PalpiteJogoPage'));
  const Regulamento = lazy(() => import('./pages/RegulamentoPage'));

  const [verify, setVerify] = React.useState('');

  async function authentication() {
    
    const a = await Auth().then((resp) => {      
      return resp;
    });

    setVerify(a);
    return a;
  }



  function RequireAuth({ children }: { children: JSX.Element }) {

    //authentication();

    const location = useLocation();

    if (verify != 'logged') {
      return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;

  }
  
  return (
    <Router>
      <Suspense fallback={<Loading/>}>
        <Await
          resolve={authentication()}
        >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<RequireAuth><Home /></RequireAuth>} />
          <Route path="/ambas-nao-marcam" element={<RequireAuth><NaoMarcamPage /></RequireAuth>} />
          <Route path="/over-2_5" element={<RequireAuth><Over25Page /></RequireAuth>} />
          <Route path="/under-1_5" element={<RequireAuth><Under15Page /></RequireAuth>} />
          <Route path="/over-1_5" element={<RequireAuth><Over15Page /></RequireAuth>} />
        </Routes>
        </Await>
      </Suspense>
    </Router>
  );
}

export default App;
