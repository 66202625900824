import * as React from 'react';
import Typography from '@mui/material/Typography';


export default function Loading() {

	const randImage = Math.floor(Math.random() * 2) + 1;
	const img = '/new_login_art.fw.png'

  return (
  	<div style={{display: 'flex',justifyContent: "center",alignItems: 'center', height: '100vh', backgroundColor: '#002040', flexDirection: 'column'  }} >
  		<div className='App-logo' style={{width:170, height: 170}}>
  			<img src={img} style={{width:170, height: 170}} />
  		</div>
  		<Typography component="div" variant="h6" sx={{fontWeight: '800', color: '#fff', fontSize: '1.5em', mt:4}}>
            Carregando...
         </Typography>
  	</div>
  );

}